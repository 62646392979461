.led-multiple-content-table {
  flex: 1;
  display: flex;
  background-color: #000;
  flex-direction: column;
  height: calc(100vh - 234px);
}

.led-multiple-content-table-header {
  display: flex;
  background-color: #000;
}

.led-multiple-content-table-header div:first-child {
  align-items: center;
  justify-content: center;
}

.led-multiple-content {
  flex: 1;
  display: flex;
  background-color: #000;
}

.led-multiple-content-child {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 8px;
}

.led-multiple-content-aqi-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 4px 8px;
}

.led-multiple-content-wqi-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.led-multiple-aqi-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.led-multiple-aqi-row div:first-child {
  justify-content: center;
  align-items: flex-end;
}

.led-multiple-aqi-row div:nth-child(2) {
  justify-content: center;
  align-items: center;
}

.led-multiple-aqi-row div:nth-child(3) {
  justify-content: center;
  align-items: flex-start;
}

.led-multiple-aqi-row-child {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.led-multiple-content-last-child {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 4px 8px;
}

.slide-multiple {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.slide-content {
  height: calc(100vh - 96px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.carousel {
  height: calc(100vh - 96px);
  flex: 1;
  display: flex;
  flex-direction: column;
}