.App {
  text-align: center;
}

.gray-bg {
  background-color: #fafafa; 
  /* background-color: #f3f3f4;  */
}

.white-bg {
  background-color: #ffffff;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.logo-title {
  font-weight: 500;
  color: white;
  text-align: center;
}

.flex-end-container {
  display: flex;  
  justify-content: flex-end;
  padding: 8px 16px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}



